import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MatchesCard from "@/components/cards/matches";
import api from "@/axios";

const TabArticles = ({ data }) => {
  return (
    <Container>
      <Row className="mb-4">
        <h2 className="text-uppercase">Materiały meczowe</h2>
      </Row>
      <Row>
        {data && data.news && data.news.length == 0 && (
          <Col>Brak dostępnych materiałów meczowych.</Col>
        )}
        {data &&
          data.news &&
          data.news.map((post, index) => (
            <Col lg={2} md={4} sm={6} className="col-6 mb-5" key={index}>
              <MatchesCard data={post} />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default TabArticles;
