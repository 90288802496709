import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/pl";
import { Col, Container, Row, Table } from "react-bootstrap";
import api from "@/axios";
import { Select } from "antd";
const { Option } = Select;

const TabArticles = ({ data }) => {
  const [apiOriginalData, setOriginalData] = useState(null);
  const [apiCurrentData, setCurrentData] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [uniqueWeeks, setUniqueWeeks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/stats/timetable`);
        const resData = response.data;
        setError(null);

        const uniqueWeeks = [];
        const ekstraklasa = resData.filter(v => v.leagueId === 0);

        const uniqueDaysEkstraklasa = [];
        ekstraklasa.filter((v, i) => {
          uniqueDaysEkstraklasa[v.startDate.split(" ")[0]] = {
            ...uniqueDaysEkstraklasa[v.startDate.split(" ")[0]],
            [i]: v,
          };
        });
        setOriginalData(ekstraklasa);

        const weeks = ekstraklasa.filter(v => {
          const isDuplicate = uniqueWeeks.includes(v.week);
          if (!isDuplicate) {
            uniqueWeeks.push(v.week);
            return true;
          }
          return false;
        });
        setUniqueWeeks(uniqueWeeks);

        const currentWeekData = ekstraklasa.find(
          v => v.matchStatus == "Fixture"
        );
        const currentWeek = data
          ? parseInt(data.week)
          : currentWeekData
          ? currentWeekData.week
          : uniqueWeeks[uniqueWeeks.length - 1];
        setCurrentWeek(currentWeek);
        const curWeekEkstraklasa = ekstraklasa.filter(
          v => v.week == currentWeek
        );
        const uniqueDaysWeekEkstraklasa = {};
        curWeekEkstraklasa.filter((v, i) => {
          uniqueDaysWeekEkstraklasa[v.startDate.split(" ")[0]] = {
            ...uniqueDaysWeekEkstraklasa[v.startDate.split(" ")[0]],
            [i]: v,
          };
        });
        setCurrentData(uniqueDaysWeekEkstraklasa);
      } catch (err) {
        setError(err.message);
        setCurrentData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleChange = v => {
    const uniqueDaysWeekEkstraklasa = {};
    const curr = apiOriginalData.filter(y => y.week == v);
    curr.filter((v, i) => {
      uniqueDaysWeekEkstraklasa[v.startDate.split(" ")[0]] = {
        ...uniqueDaysWeekEkstraklasa[v.startDate.split(" ")[0]],
        [i]: v,
      };
    });
    setCurrentData(uniqueDaysWeekEkstraklasa);
  };

  return (
    <section className="commonSection">
      <Container className="mb-5">
        <Row>
          {!loading && uniqueWeeks && (
            <Col lg={12} sm={12} className="text-start">
              <h2>{currentWeek} kolejka</h2>
            </Col>
          )}

          {!data ||
            (data && data.week?.length == 0 && (
              <Col>
                Nie znaleziono kolejki dla wybranego spotkania. Wyświetlanie
                najnowszej kolejki Ekstraklasy.
              </Col>
            ))}
        </Row>
      </Container>
      {loading && <span className="loader"></span>}
      {error && (
        <div className="text-dark">{`Wystąpił problem z wczytaniem terminarza - ${error}`}</div>
      )}
      {!loading &&
        apiCurrentData &&
        Object.keys(apiCurrentData).map((dates, index) => (
          <Container key={index} className="text-dark">
            <Row className="stats-container rounded">
              <Col className="text-start">
                <h4 className="m-0 py-3 ps-0 ps-sm-0 ps-md-0 ps-lg-5 fw-bold text-uppercase text-center text-sm-center text-md-center text-lg-center text-xl-start">
                  <Moment date={dates} format="dddd DD.MM.YYYY"></Moment>
                </h4>
              </Col>
            </Row>
            {Object.keys(apiCurrentData[dates]).map(incrData => (
              <Row className="justify-content-between align-items-center py-5 fw-bold text-uppercase">
                <Col
                  lg={"auto"}
                  xl={"auto"}
                  className="d-none d-sm-none d-md-none d-lg-none d-xl-block"
                >
                  <div className="d-flex justify-content-center">
                    {apiCurrentData[dates][incrData].homeLastSix &&
                      apiCurrentData[dates][incrData].homeLastSix.length != 6 &&
                      Array(
                        6 - apiCurrentData[dates][incrData].homeLastSix.length
                      )
                        .fill(null)
                        .map((el, i) => (
                          <div
                            key={i}
                            className="ms-3 px-2 rounded text-white form-null"
                          >
                            {"-"}
                          </div>
                        ))}
                    {apiCurrentData[dates][incrData].homeLastSix &&
                      apiCurrentData[dates][incrData].homeLastSix
                        .split("")
                        .map((formSymbol, formIndex) => (
                          <div
                            className={`ms-3 px-2 rounded text-white ${
                              formSymbol == "W" && "form-win"
                            } ${formSymbol == "D" && "form-draw"} ${
                              formSymbol == "L" && "form-lose"
                            }`}
                            key={formIndex}
                          >
                            {formSymbol == "W" && "Z"}
                            {formSymbol == "D" && "R"}
                            {formSymbol == "L" && "P"}
                          </div>
                        ))}
                  </div>
                </Col>
                <Col
                  sm={"auto"}
                  md={"auto"}
                  lg={"auto"}
                  xl={2}
                  className="col-auto d-flex align-items-center text-center text-sm-center text-md-center text-lg-center text-xl-end my-4 my-sm-4 my-md-4 my-lg-4 my-xl-0 justify-content-end"
                >
                  <span className="d-none d-sm-none d-md-none d-lg-inline-block">
                    {apiCurrentData[dates][incrData].homeConName}
                  </span>
                  <img
                    src={
                      apiCurrentData[dates][incrData].homeConImage
                        ? `${process.env.GATSBY_API_BASE_URL}/${apiCurrentData[dates][incrData].homeConImage.path}`
                        : ""
                    }
                    className="logos-size ms-3"
                  />
                </Col>
                <Col
                  lg={"auto"}
                  md={"auto"}
                  sm={"auto"}
                  className="col-auto text-center timetable-score"
                >
                  {apiCurrentData[dates][incrData].homeConGoals}
                </Col>
                <Col
                  sm={"auto"}
                  md={"auto"}
                  lg={"auto"}
                  xl={"auto"}
                  className="col-auto text-center mx-lg-2 my-3 my-sm-3 my-md-3 my-lg-3 my-xl-0"
                >
                  <div>
                    {moment(apiCurrentData[dates][incrData].startDate).format(
                      "HH:mm"
                    ) != "00:00"
                      ? moment(
                          apiCurrentData[dates][incrData].startDate
                        ).format("HH:mm")
                      : "-"}
                  </div>
                  vs
                </Col>
                <Col
                  lg={"auto"}
                  md={"auto"}
                  sm={"auto"}
                  className="col-auto text-center timetable-score"
                >
                  {apiCurrentData[dates][incrData].awayConGoals}
                </Col>
                <Col
                  sm={"auto"}
                  md={"auto"}
                  lg={"auto"}
                  xl={2}
                  className="col-auto d-flex align-items-center text-center text-sm-center text-md-center text-lg-center text-xl-start my-4 my-sm-4 my-md-4 my-lg-4 my-xl-0 justify-content-start"
                >
                  <img
                    src={
                      apiCurrentData[dates][incrData].awayConImage
                        ? `${process.env.GATSBY_API_BASE_URL}/${apiCurrentData[dates][incrData].awayConImage.path}`
                        : ""
                    }
                    className="logos-size me-3"
                  />
                  <span className="d-none d-sm-none d-md-none d-lg-inline-block">
                    {apiCurrentData[dates][incrData].awayConName}
                  </span>
                </Col>
                <Col
                  lg={"auto"}
                  xl={"auto"}
                  className="d-none d-sm-none d-md-none d-lg-none d-xl-block"
                >
                  <div className="d-flex justify-content-center">
                    {apiCurrentData[dates][incrData].awayLastSix &&
                      apiCurrentData[dates][incrData].awayLastSix
                        .split("")
                        .map((formSymbol, formIndex) => (
                          <div
                            className={`ms-3 px-2 rounded text-white ${
                              formSymbol == "W" && "form-win"
                            } ${formSymbol == "D" && "form-draw"} ${
                              formSymbol == "L" && "form-lose"
                            }`}
                            key={formIndex}
                          >
                            {formSymbol == "W" && "Z"}
                            {formSymbol == "D" && "R"}
                            {formSymbol == "L" && "P"}
                          </div>
                        ))}
                    {apiCurrentData[dates][incrData].awayLastSix &&
                      apiCurrentData[dates][incrData].awayLastSix.length != 6 &&
                      Array(
                        6 - apiCurrentData[dates][incrData].awayLastSix.length
                      )
                        .fill(null)
                        .map((el, i) => (
                          <div
                            key={i}
                            className="ms-3 px-2 rounded text-white form-null"
                          >
                            {"-"}
                          </div>
                        ))}
                  </div>
                </Col>
              </Row>
            ))}
          </Container>
        ))}
    </section>
  );
};

export default TabArticles;
