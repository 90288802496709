import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { API, isLoggedIn } from "@/api/auth";
import Countdown, { zeroPad } from "react-countdown";
import Moment from "react-moment";
import Swal from "sweetalert2";
import "moment/locale/pl";

const TabTyper = ({ data }) => {
  const {
    id,
    week,
    homeConName,
    awayConName,
    homeCrestImage,
    awayCrestImage,
    statusId,
    betstats,
  } = data;

  const [apiData, setApiData] = useState(null);
  const [apiClientData, setApiClientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseInfo, setResponseInfo] = useState(null);

  useEffect(async () => {
    if (isLoggedIn()) {
      const responseClient = await API.get("/api/client/o/get");
      setApiClientData(responseClient.data);
    }
  }, []);

  const bet = v => {
    let questionMap = [
      {
        o: "1",
        q: `Czy na pewno chcesz obstawić wygraną drużyny ${homeConName}?`,
      },
      { o: "X", q: `Czy na pewno chcesz obstawić remis w tym meczu?` },
      {
        o: "2",
        q: `Czy na pewno chcesz obstawić wygraną drużyny ${awayConName}?`,
      },
    ];

    Swal.fire({
      title: "Wytypuj wynik",
      text: questionMap.find(x => x.o == v).q,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nie",
      confirmButtonText: "Tak",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          let response = await API.post("/api/matchroom/bet", {
            matchroomId: id,
            bet: v,
          });

          if (response.data.status == "success")
            Swal.fire("Udało się", response.data.message, "success");
          else Swal.fire("Wystąpił błąd", response.data.message, "error");
        } catch (err) {
          if (err.response.data.error_code == 403) {
            Swal.fire(
              "Wystąpił błąd",
              "Obstawianie zakładów dostępne jest tylko dla zalogowanych użytkowników.",
              "error"
            );
          }
        }
      }
    });
  };

  return (
    <>
      {loading && <span className="loader"></span>}
      <section className="commonSection py-0">
        <Container className="jaga-typer-details-box-container">
          <Row className="justify-content-center align-items-center text-center">
            <Col className="order-1">
              <div className="rounded-circle fw-bold">
                <img
                  src={
                    homeCrestImage
                      ? `${process.env.GATSBY_API_BASE_URL}/${homeCrestImage.path}`
                      : ""
                  }
                  className="mb-4 img-fluid"
                />
              </div>
              <span className="fw-bold">{homeConName}</span>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={"auto"}
              className={`order-3 order-sm-3 order-md-3 order-lg-2 mt-5 mt-sm-5 mt-md-5 ${
                statusId == 1 && "mt-lg-auto"
              }`}
            >
              {/* <Countdown
                className="countdown w-50"
                date={exampleDate}
                renderer={renderer}
              /> */}
              {statusId == 1 ? (
                <>
                  <Row className="flex-column text-center fw-bold">
                    WYTYPUJ WYNIK
                  </Row>
                  <Row className="text-center mt-4 fw-bold justify-content-center">
                    <div
                      className="rounded-circle jaga-typer-details-box-bet"
                      onClick={() => bet("1")}
                    >
                      1
                    </div>
                    <div
                      className="rounded-circle jaga-typer-details-box-bet"
                      onClick={() => bet("X")}
                    >
                      X
                    </div>
                    <div
                      className="rounded-circle jaga-typer-details-box-bet"
                      onClick={() => bet("2")}
                    >
                      2
                    </div>
                  </Row>
                </>
              ) : (
                <div
                  className="text-center mb-2 jaga-typer-details-box-time"
                  style={{
                    marginTop: "-25px",
                    borderRadius: "12px",
                    background: "#d81921",
                    color: "white",
                    padding: "5px 20px",
                    fontSize: "20px",
                  }}
                >
                  Typowanie zakończone
                </div>
              )}
            </Col>
            <Col className="order-2 order-sm-2 order-md-2 order-lg-3">
              <div className="rounded-circle fw-bold">
                <img
                  src={
                    awayCrestImage
                      ? `${process.env.GATSBY_API_BASE_URL}/${awayCrestImage.path}`
                      : ""
                  }
                  className="mb-4 img-fluid"
                />
              </div>
              <span className="fw-bold">{awayConName}</span>
            </Col>
          </Row>
          {apiClientData &&
            apiClientData.bets.length > 0 &&
            apiClientData.bets.find(v => v.matchroomId == id) && (
              <Row className="text-center mt-4 fw-bold justify-content-center">
                <div className="jaga-typer-details-prc">
                  {betstats.homeBetPrc}
                </div>
                <div className="jaga-typer-details-prc">
                  {betstats.drawBetPrc}
                </div>
                <div className="jaga-typer-details-prc">
                  {betstats.awayBetPrc}
                </div>
              </Row>
            )}
        </Container>
      </section>
    </>
  );
};

export default TabTyper;
