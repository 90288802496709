import React from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import TabLive from "@/components/matchroom/tab-live.js";
import TabStats from "@/components/matchroom/tab-stats.js";
import TabTyper from "@/components/matchroom/tab-typer.js";
import TabArticles from "@/components/matchroom/tab-articles.js";
import TabVoting from "@/components/matchroom/tab-voting.js";
import TabLineups from "@/components/matchroom/tab-lineups.js";
import TabTimetable from "@/components/matchroom/tab-timetable.js";

import pitchIcon from "@/images/icons/pitch.svg";
import liveIcon from "@/images/icons/live.svg";
import statsIcon from "@/images/icons/stats.svg";
import vsIcon from "@/images/icons/vs.svg";
import matchDayIcon from "@/images/icons/matchday.svg";
import votingIcon from "@/images/icons/voting.svg";
import articlesIcon from "@/images/icons/articles.svg";

const MatchroomTabs = ({ data }) => {
  return (
    <section className="commonSection pt-0 matchroom-tabs-top-section">
      <Tab.Container id="left-tabs-example" defaultActiveKey="lineups">
        <Container className="matchroom-tabs-top-container py-4 px-0 px-sm-0 px-md-0 px-lg-4 text-center">
          <Row>
            <Col>
              <Nav className="justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-between">
                <Nav.Item>
                  <Nav.Link eventKey="lineups">
                    <img src={pitchIcon} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="live">
                    <img src={liveIcon} />
                  </Nav.Link>
                </Nav.Item>
                {data && data.leagueId !== 1 && (
                  <Nav.Item>
                    <Nav.Link eventKey="stats">
                      <img src={statsIcon} />
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link eventKey="typer">
                    <img src={vsIcon} />
                  </Nav.Link>
                </Nav.Item>
                {data && data.leagueId !== 1 && (
                  <Nav.Item>
                    <Nav.Link eventKey="match-day">
                      <img src={matchDayIcon} />
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link eventKey="voting">
                    <img src={votingIcon} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="articles">
                    <img src={articlesIcon} />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <Tab.Content>
            <Tab.Pane eventKey="lineups">
              <TabLineups data={data && data} />
            </Tab.Pane>
            <Tab.Pane eventKey="live">
              <TabLive data={data && data} />
            </Tab.Pane>
            <Tab.Pane eventKey="stats">
              <TabStats data={data && data} />
            </Tab.Pane>
            <Tab.Pane eventKey="typer">
              <TabTyper data={data && data} />
            </Tab.Pane>
            <Tab.Pane eventKey="match-day">
              <TabTimetable data={data && data} />
            </Tab.Pane>
            <Tab.Pane eventKey="voting">
              <TabVoting data={data && data} />
            </Tab.Pane>
            <Tab.Pane eventKey="articles">
              <TabArticles data={data && data} />
            </Tab.Pane>
          </Tab.Content>
        </Container>
      </Tab.Container>
    </section>
  );
};

export default MatchroomTabs;
