import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import api from "@/axios";
import Swal from "sweetalert2";

import iGoal from "@/images/icons/incidents/goal-big.svg";
import iSub from "@/images/icons/incidents/substitute.svg";
import iYCard from "@/images/icons/incidents/yellow-card.svg";
import iRCard from "@/images/icons/incidents/red-card.svg";
import presentationPlayerPlaceholder from "@/images/players/presentation-player-placeholder.png";
import { getMinutesPlayedLabel } from "../../helpers/index";

const TabVoting = ({ data }) => {
  const incidentsImgs = {
    Goal: iGoal,
    "Goal Own": iGoal,
    "Goal Penalty": iGoal,
    Substitute: iSub,
    "Yellow Card": iYCard,
    "Red Card": iRCard,
  };
  const { playersToVote } = data;

  function submitVote(playerId) {
    Swal.fire({
      title: "Głosowanie",
      text: "Czy na pewno chcesz oddać głos?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nie",
      confirmButtonText: "Tak",
    }).then(async result => {
      if (result.isConfirmed) {
        let dataRes = await sendData();
        if (dataRes.status == "success") {
          Swal.fire("Zagłosowano", dataRes.message, "success");
        } else {
          Swal.fire("Błąd", dataRes.message, "error");
        }
      }
    });
    const sendData = async () => {
      try {
        const response = await api.post("/matchroom/vote", {
          id: playerId,
        });
        return response.data;
      } catch (err) {
        return err;
      }
    };
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: "400px", marginTop: "75px" }}
      />
      <Container>
        <Row className="text-uppercase bg-red text-white border-radius-light mx-0 mx-lg-5 px-5 py-2 mb-4">
          GŁOSOWANIE NA ZAWODNIKA MECZU
        </Row>
        <Row>
          {playersToVote &&
            playersToVote.map((p, i) => (
              <Col key={i} xl={3} lg={4} md={12} sm={12} className="my-3">
                <div className="voting-col d-flex fw-bold text-dark rounded-top">
                  <div className="p-3 stats-best-box justify-content-start">
                    <div className="stats-matches stats-shirt-box fs-4">
                      {p.shirtNumber}
                    </div>
                    <div className="fs-6 fw-normal mt-2 lh-sm">
                      {p.firstname} {p.lastname}
                    </div>
                    <div className="fs-6 fw-light lh-1 text-grey-light mt-1">
                      <small>{p.publicPosition}</small>
                    </div>
                    <div className="fs-5 fw-semibold mt-5">
                      {getMinutesPlayedLabel(p.minutesPlayed)}
                    </div>
                  </div>
                  <img
                    className="me-0 align-self-end voting-player-img"
                    src={
                      p.presentationImage
                        ? `${process.env.GATSBY_API_BASE_URL}/${p.presentationImage.path}`
                        : presentationPlayerPlaceholder
                    }
                  ></img>
                </div>
                <button
                  onClick={() => submitVote(p.id)}
                  className="w-100 rounded-bottom voting-box-button py-2 fw-semibold"
                >
                  GŁOSUJĘ
                </button>
              </Col>
            ))}
        </Row>
        {/* <Row key={i} className="align-items-center mb-3">
            <Col className="voting-number text-center">{p.shirtNumber}</Col>
            <Col>
              <h4>
                {p.firstname} {p.lastname}
              </h4>
              <h5 className="text-grey-light">{p.position}</h5>
            </Col>
            <Col className="text-end">
              <img src={GoalIcon} className="img-fluid" />
            </Col>
            <Col>
              <button className="common_btn jag_bg py-4">
                <span>Głosuję</span>
              </button>
            </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default TabVoting;
