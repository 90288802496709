import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import iGoal from "@/images/icons/incidents/goal-big.svg";
import iSub from "@/images/icons/incidents/substitute.svg";
import iYCard from "@/images/icons/incidents/yellow-card.svg";
import iRCard from "@/images/icons/incidents/red-card.svg";

const TabLive = ({ data }) => {
  const incidentsImgs = {
    Goal: iGoal,
    "Goal Own": iGoal,
    "Goal Penalty": iGoal,
    Substitute: iSub,
    "Yellow Card": iYCard,
    "Red Card": iRCard,
  };
  const { rels } = data;
  return (
    <Container>
      <Row className="mb-4">
        <h2 className="text-uppercase">Relacja Live</h2>
      </Row>
      {rels && rels.length == 0 && (
        <Row className="my-3 justify-content-start align-items-center text-center live-row">
          Relacja jeszcze się nie rozpoczęła.
        </Row>
      )}
      {rels &&
        rels.map((rel, i) => (
          <Row
            key={i}
            className="my-3 justify-content-start align-items-center text-center live-row"
          >
            <Col sm={12} md={3} lg={2} className="col-12 live-min py-3">
              {rel.minuteString}
            </Col>
            <Col
              sm={12}
              md={6}
              lg={rel.incidents && rel.incidents.length > 0 ? 8 : 10}
              className="col-12 text-start"
            >
              {rel.message}
              {rel.embedText && !rel.selectedImage && (
                <div
                  dangerouslySetInnerHTML={{ __html: rel.embedText }}
                  className="mt-4"
                ></div>
              )}
              {rel.selectedImage && (
                <div className="live-image-box">
                  <img
                    src={`${process.env.GATSBY_API_BASE_URL}/${rel.selectedImage.path}`}
                    className="mt-4"
                  />
                </div>
              )}
            </Col>
            {rel.incidents && rel.incidents.length > 0 && (
              <Col
                sm={12}
                md={3}
                lg={2}
                className="col-12 live-events-icons py-3"
              >
                {rel.incidents.map((inc, incI) => (
                  <img
                    key={incI}
                    src={incidentsImgs[inc.type]}
                    className="mx-2 img-fluid live-icons"
                  />
                ))}
              </Col>
            )}
          </Row>
        ))}
    </Container>
  );
};

export default TabLive;
