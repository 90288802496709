import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/pl";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactTooltip from "react-tooltip";

import sliderBg from "@/images/bg/matchroom/top.jpg";
import ekstraklasaLogo from "@/images/leagues/ekstraklasa-white.png";
import friendlyMatchLogo from "@/images/leagues/friendly-match-black.png";
import polishCupLogo from "@/images/leagues/polish-cup.png";
import ChampionsLeagueLogo from "@/images/leagues/Logo_UEFA_Champions_League.png";
import EuropeLeagueLogo from "@/images/leagues/Europa_League_2021.png";
import ConferenceLeagueLogo from "@/images/leagues/uefa-europa-conference-league.png";

import iGoal from "@/images/icons/incidents/goal-white.svg";
import iSub from "@/images/icons/incidents/substitute.svg";
import iYCard from "@/images/icons/incidents/yellow-card.svg";
import iRCard from "@/images/icons/incidents/red-card.svg";

SwiperCore.use([Autoplay, EffectFade]);
const SliderTop = ({ data }) => {
  const {
    matchDate,
    week,
    leagueId,
    leagueName,
    homeConShortName,
    awayConShortName,
    homeCrestImage,
    awayCrestImage,
    homeGoals,
    awayGoals,
    time,
    matchMinute,
    incidents,
  } = data;

  const incidentsImgs = {
    Goal: iGoal,
    "Goal Own": iGoal,
    "Goal Penalty": iGoal,
    Substitute: iSub,
    "Yellow Card": iYCard,
    "Red Card": iRCard,
  };

  const incidentsTypes = {
    Goal: "Gol",
    "Goal Own": "Gol samobójczy",
    "Goal Penalty": "Gol z rzutu karnego",
    Substitute: "Zmiana",
    "Yellow Card": "Żółta kartka",
    "Red Card": "Czerwona kartka",
  };

  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
  };

  return (
    <section className="main-slider slider-subpages matchroom-slider">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url('${sliderBg}')` }}
          />
          <Container className="animated fadeInLeft matchroom-top-container">
            <Row>
              <Col className="text-center">
                <p className="main-slider__subtext matchroom-slider-date">
                  <Moment date={matchDate} format="dddd" />,{" "}
                  <Moment date={matchDate} format="DD.MM.YYYY" />
                  {" | "}
                  <Moment date={matchDate} format="H:mm" />
                </p>
                {[0, 2, 3].includes(leagueId) && (
                  <>
                    <img
                      src={ekstraklasaLogo}
                      className="matchroom-league-logo"
                      alt=""
                    />
                    <p className="text-uppercase text-white fw-bold mb-0">
                      {`${week}. KOLEJKA`}
                    </p>
                    <p className="text-uppercase text-white fw-bold">
                      {leagueName}
                    </p>
                  </>
                )}
                {leagueId === 7 && (
                  <>
                    <img
                      src={friendlyMatchLogo}
                      className="matchroom-league-logo"
                      alt=""
                    />
                    <p className="text-uppercase text-white fw-bold">
                      Mecz towarzyski
                    </p>
                  </>
                )}
                {leagueId === 1 && (
                  <>
                    <img
                      src={polishCupLogo}
                      className="matchroom-league-logo"
                      alt=""
                    />
                    <p className="text-uppercase text-white fw-bold">{week}</p>
                  </>
                )}
                {leagueId === 4 && (
                  <>
                    <img
                      src={ChampionsLeagueLogo}
                      className="matchroom-league-logo-big"
                      alt=""
                    />
                    <p className="text-uppercase text-white fw-bold mb-0">
                      {week}
                    </p>
                    <p className="text-uppercase text-white fw-bold">
                      {leagueName}
                    </p>
                  </>
                )}
                {leagueId === 5 && (
                  <>
                    <img
                      src={EuropeLeagueLogo}
                      className="matchroom-league-logo-big"
                      alt=""
                    />
                    <p className="text-uppercase text-white fw-bold mb-0">
                      {week}
                    </p>
                    <p className="text-uppercase text-white fw-bold">
                      {leagueName}
                    </p>
                  </>
                )}
                {leagueId === 6 && (
                  <>
                    <img
                      src={ConferenceLeagueLogo}
                      className="matchroom-league-logo-big"
                      alt=""
                    />
                    <p className="text-uppercase text-white fw-bold mb-0">
                      {week}
                    </p>
                    <p className="text-uppercase text-white fw-bold">
                      {leagueName}
                    </p>
                  </>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center align-items-start py-3 py-lg-5 fw-bold text-uppercase text-white">
              <Col
                sm={6}
                md={6}
                lg={5}
                className="col-6 text-center text-sm-center text-md-center text-lg-end matchroom-team-names-top"
              >
                <Row className="justify-content-center align-items-center flex-column-reverse flex-md-row gap-3">
                  <Col>{homeConShortName}</Col>
                  <Col className="col-auto">
                    <img
                      src={
                        homeCrestImage
                          ? `${process.env.GATSBY_API_BASE_URL}/${homeCrestImage.path}`
                          : ""
                      }
                      className="logos-size-matchroom-top ms-0 ms-md-4"
                      alt=""
                    />
                  </Col>
                </Row>
                <div className="matchroom-scorers-top mt-3 mt-lg-5">
                  {incidents &&
                    incidents
                      .filter((v) => {
                        if (v.type == "Goal" && v.playerFrom.side == "home") {
                          return v;
                        }
                        if (
                          v.type == "Goal Own" &&
                          v.playerFrom.side == "away"
                        ) {
                          v.goalType = " (S)";
                          return v;
                        }
                        if (
                          v.type == "Goal Penalty" &&
                          v.playerFrom.side == "home"
                        ) {
                          v.goalType = " (K)";
                          return v;
                        }
                      })
                      .map((data) => (
                        <div>
                          {data.playerFrom.lastname}
                          {data.goalType && data.goalType} {data.minuteString}
                        </div>
                      ))}
                </div>
              </Col>
              <Col
                sm={12}
                md={12}
                lg={2}
                className="col-12 order-first order-lg-2 text-center matchroom-score mt-2 mt-lg-5 mb-5 mb-sm-5 mb-md-5 mb-lg-0"
              >
                <div>
                  {homeGoals} : {awayGoals}
                </div>
                <div className="fs-3 mt-3 mt-lg-5">{time}</div>
              </Col>
              <Col
                sm={6}
                md={6}
                lg={5}
                className="col-6 order-2 order-lg-last text-center text-sm-center text-md-center text-lg-start matchroom-team-names-top"
              >
                <Row className="justify-content-center align-items-center flex-column flex-md-row gap-3">
                  <Col className="col-auto">
                    <img
                      src={
                        awayCrestImage
                          ? `${process.env.GATSBY_API_BASE_URL}/${awayCrestImage.path}`
                          : ""
                      }
                      className="logos-size-matchroom-top me-0 me-md-4"
                      alt=""
                    />
                  </Col>
                  <Col>{awayConShortName}</Col>
                </Row>
                <div className="matchroom-scorers-top mt-3 mt-lg-5">
                  {incidents &&
                    incidents
                      .filter((v) => {
                        if (v.type == "Goal" && v.playerFrom.side == "away") {
                          return v;
                        }
                        if (
                          v.type == "Goal Own" &&
                          v.playerFrom.side == "home"
                        ) {
                          v.goalType = " (S)";
                          return v;
                        }
                        if (
                          v.type == "Goal Penalty" &&
                          v.playerFrom.side == "away"
                        ) {
                          v.goalType = " (K)";
                          return v;
                        }
                      })
                      .map((data) => (
                        <div>
                          {data.playerFrom.lastname}
                          {data.goalType && data.goalType} {data.minuteString}
                        </div>
                      ))}
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mx-auto text-white fw-bold progress-game-row text-uppercase d-none d-sm-none d-md-none d-lg-flex">
              <Col className="position-relative px-0">
                {incidents &&
                  incidents
                    .filter(
                      (v) =>
                        parseInt(v.minute) <= 90 &&
                        ((v.playerFrom.side == "home" &&
                          v.type != "Goal Own") ||
                          (v.playerFrom.side == "away" && v.type == "Goal Own"))
                    )
                    .map((data, i) => {
                      const found = incidents.filter(
                        (x) =>
                          parseInt(x.minute) == data.minute &&
                          (x.playerFrom.side == data.playerFrom.side ||
                            (x.playerFrom.side != data.playerFrom.side &&
                              x.type == "Goal Own"))
                      );
                      const num = found.length;
                      if (num > 1) {
                        return (
                          <div
                            key={i}
                            className={`progress-incident-overlap-${data.playerFrom.side} progress-incidents-${data.playerFrom.side}`}
                            style={{
                              left: `${((data.minute - 1) / 45) * 100}%`,
                              marginTop: `-${22 * (num - 1)}px`,
                            }}
                          >
                            {found.map(
                              (z) =>
                                data.playerFrom.side == z.playerFrom.side && (
                                  <>
                                    <img
                                      data-tip={`${data.minute}' - ${
                                        incidentsTypes[z.type]
                                      } - ${z.playerFrom.lastname} ${
                                        z.playerTo
                                          ? `-> ${z.playerTo.lastname}`
                                          : ""
                                      }`}
                                      src={incidentsImgs[z.type]}
                                      alt=""
                                    />
                                    <ReactTooltip type="light" effect="solid" />
                                  </>
                                )
                            )}
                          </div>
                        );
                      }
                      return (
                        <>
                          <div
                            key={i}
                            data-tip={`${data.minute}' - ${
                              incidentsTypes[data.type]
                            } - ${data.playerFrom.lastname} ${
                              data.playerTo
                                ? `-> ${data.playerTo.lastname}`
                                : ""
                            }`}
                            className={`progress-incidents-${
                              data.type == "Goal Own"
                                ? "home"
                                : data.playerFrom.side
                            } ${
                              data.type == "Goal Own" && "progress-own-goal"
                            }`}
                            style={{
                              left: `${((data.minute - 1) / 45) * 100}%`,
                            }}
                          >
                            <img src={incidentsImgs[data.type]} alt="" />
                          </div>
                          <ReactTooltip type="light" effect="solid" />
                        </>
                      );
                    })}
                {incidents &&
                  incidents
                    .filter(
                      (v) =>
                        parseInt(v.minute) <= 90 &&
                        ((v.playerFrom.side == "away" &&
                          v.type != "Goal Own") ||
                          (v.playerFrom.side == "home" && v.type == "Goal Own"))
                    )
                    .map((data, i) => {
                      const found = incidents.filter(
                        (x) =>
                          parseInt(x.minute) == data.minute &&
                          (x.playerFrom.side == data.playerFrom.side ||
                            (x.playerFrom.side != data.playerFrom.side &&
                              x.type == "Goal Own"))
                      );
                      const num = found.length;
                      if (num > 1) {
                        return (
                          <div
                            key={i}
                            className={`progress-incident-overlap-${data.playerFrom.side} progress-incidents-${data.playerFrom.side}`}
                            style={{
                              left: `${((data.minute - 1) / 45) * 100}%`,
                              marginBottom: `-${22 * (num - 1)}px`,
                            }}
                          >
                            {found.map(
                              (z) =>
                                data.playerFrom.side == z.playerFrom.side && (
                                  <>
                                    <img
                                      data-tip={`${data.minute}' - ${
                                        incidentsTypes[z.type]
                                      } - ${z.playerFrom.lastname} ${
                                        z.playerTo
                                          ? `-> ${z.playerTo.lastname}`
                                          : ""
                                      }`}
                                      src={incidentsImgs[z.type]}
                                      alt=""
                                    />
                                    <ReactTooltip type="light" effect="solid" />
                                  </>
                                )
                            )}
                          </div>
                        );
                      }
                      return (
                        <>
                          <div
                            key={i}
                            data-tip={`${data.minute}' - ${
                              incidentsTypes[data.type]
                            } - ${data.playerFrom.lastname} ${
                              data.playerTo
                                ? `-> ${data.playerTo.lastname}`
                                : ""
                            }`}
                            className={`progress-incidents-${
                              data.type == "Goal Own"
                                ? "away"
                                : data.playerFrom.side
                            } ${
                              data.type == "Goal Own" && "progress-own-goal"
                            }`}
                            style={{
                              left: `${((data.minute - 1) / 45) * 100}%`,
                            }}
                          >
                            <img src={incidentsImgs[data.type]} alt="" />
                          </div>
                          <ReactTooltip type="light" effect="solid" />
                        </>
                      );
                    })}
                <span>{homeConShortName && homeConShortName.slice(0, 3)}</span>
                <ProgressBar
                  className="matchroom-progressbar-top"
                  variant="danger"
                  now={matchMinute && (matchMinute / 45) * 100}
                />
                <span>{awayConShortName && awayConShortName.slice(0, 3)}</span>
              </Col>
              <Col className="position-relative px-0">
                <ProgressBar
                  className="matchroom-progressbar-top"
                  variant="danger"
                  now={matchMinute && ((matchMinute - 45) / 45) * 100}
                />
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SliderTop;
