import "@/css/matchroom-lineups.css";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
if (typeof window !== "undefined") { window.jQuery = window.$ = require("jquery"); }
import api from "@/axios";

if (typeof window !== "undefined") {
require("velocity-animate");
}

import iGoal from "@/images/icons/incidents/goal-big.svg";
import iSub from "@/images/icons/incidents/substitute.svg";
import iYCard from "@/images/icons/incidents/yellow-card.svg";
import iRCard from "@/images/icons/incidents/red-card.svg";

import playerGrid from "@/images/matchroom/playergrid.jpg";

const TabLineups = ({ data }) => {
  require("@/components/matchroom/lineups-logic.js");

  const incidentsImgs = {
    Goal: iGoal,
    "Goal Own": iGoal,
    "Goal Penalty": iGoal,
    Substitute: iSub,
    "Yellow Card": iYCard,
    "Red Card": iRCard,
  };

  const { homeConShortName, awayConShortName, players } = data;

  const [state, setState] = useState({
    players: {
      home: [],
      away: [],
    },
    playersList: [],
    playersSubsList: [],
  });

  useEffect(() => {
    if (players) {
      let home = players.starting.filter(v => v.side == "home" && v.position);
      let away = players.starting.filter(v => v.side == "away" && v.position);

      home = home.map(m => {
        return {
          name: m.lastname,
          asset: m.gridImage
            ? `${process.env.GATSBY_API_BASE_URL}/${m.gridImage.path}`
            : playerGrid,
          shirt: m.shirtNumber,
          pos: m.publicPosition,
          minutes: m.minutesPlayed ? m.minutesPlayed : "-",
          x: m.x,
          y: m.y,
        };
      });

      away = away.map(m => {
        return {
          name: m.lastname,
          asset: m.gridImage
            ? `${process.env.GATSBY_API_BASE_URL}/${m.gridImage.path}`
            : playerGrid,
          shirt: m.shirtNumber,
          pos: m.publicPosition,
          minutes: m.minutesPlayed ? m.minutesPlayed : "-",
          x: m.x,
          y: m.y,
        };
      });

      setState({
        ...state,
        players: { home: home, away: away },
        playersList: players.starting.filter(v => v.side == "home"),
        playersSubsList: players.substitute.filter(v => v.side == "home"),
      });

      if (typeof window !== "undefined") {
        $(document).ready(function () {
          window.initPitch({ home: home, away: away });
        });
      }
    }
  }, [players && players.starting.length > 0]);

  const switchData = () => {
    let pitchPos = typeof window !== "undefined" ? window.getState() : '';

    if (pitchPos)
      setState({
        ...state,
        playersList: players.starting.filter(v => v.side == "home"),
        playersSubsList: players.substitute.filter(v => v.side == "home"),
      });
    else
      setState({
        ...state,
        playersList: players.starting.filter(v => v.side == "away"),
        playersSubsList: players.substitute.filter(v => v.side == "away"),
      });
  };

  return (
    <>
      <div class="main-pitch">
        <div class="static">
          <div class="js-switcher switcher">
            <a
              onClick={switchData}
              href="#"
              class="js-switch disabled switch-btn"
            >
              {homeConShortName}
            </a>
            <a onClick={switchData} href="#" class="js-switch switch-btn">
              {awayConShortName}
            </a>
          </div>
        </div>
        <div class="js-stage stage texture">
          <div class="js-world world">
            <div class="team js-team"></div>
            <div class="terrain js-terrain">
              <div class="field field--alt"></div>
              <div class="field ground">
                <div class="field__texture field__texture--gradient"></div>
                <div class="field__texture field__texture--gradient-b"></div>
                <div class="field__texture field__texture--grass"></div>
                <div class="field__line field__line--goal"></div>
                <div class="field__line field__line--goal field__line--goal--far"></div>
                <div class="field__line field__line--outline"></div>
                <div class="field__line field__line--penalty"></div>
                <div class="field__line field__line--penalty-arc"></div>
                <div class="field__line field__line--penalty-arc field__line--penalty-arc--far"></div>
                <div class="field__line field__line--mid"></div>
                <div class="field__line field__line--circle"></div>
                <div class="field__line field__line--penalty field__line--penalty--far"></div>
              </div>
              <div class="field__side field__side--front"></div>
              <div class="field__side field__side--left"></div>
              <div class="field__side field__side--right"></div>
              <div class="field__side field__side--back"></div>
            </div>
          </div>
          <div class="loading js-loading">WCZYTYWANIE...</div>
        </div>
      </div>
      <Row className="row-banner-matchroom-teams text-uppercase bg-red text-white border-radius-light mx-0 mx-lg-auto px-5 py-2 mb-4 text-center">
        SKŁAD
      </Row>
      {state.playersList &&
        state.playersList.map((p, i) => (
          <Row
            key={i}
            className="row-list-matchroom-teams align-items-center mb-3 mx-0 mx-lg-auto"
          >
            <Col className="col-2 voting-number text-center">
              {p.shirtNumber}
            </Col>
            <Col>
              <h4>
                {p.firstname} {p.lastname}
              </h4>
              <h5 className="text-grey-light">{p.publicPosition}</h5>
            </Col>
            {/* <Col className="text-end">
                <img src={incidentsImgs[inc.type]} className="img-fluid" />
                <img src={iGoal} className="img-fluid" />
              </Col>
              <Col>
                <button
                  className="common_btn jag_bg py-4"
                  onClick={submitVote(p.id)}
                >
                  <span>Głosuję</span>
                </button>
              </Col> */}
          </Row>
        ))}
      <Row className="row-banner-matchroom-teams text-uppercase bg-red text-white border-radius-light mx-0 mx-lg-auto px-5 py-2 mb-4 text-center">
        ŁAWKA REZERWOWYCH
      </Row>
      {state.playersSubsList &&
        state.playersSubsList.map((p, i) => (
          <Row
            key={i}
            className="row-list-matchroom-teams align-items-center mb-3 mx-auto"
          >
            <Col className="col-2 voting-number text-center">
              {p.shirtNumber}
            </Col>
            <Col>
              <h4>
                {p.firstname} {p.lastname}
              </h4>
              <h5 className="text-grey-light">Rezerwowy</h5>
            </Col>
            {/* <Col className="text-end">
                <img src={incidentsImgs[inc.type]} className="img-fluid" />
                <img src={iGoal} className="img-fluid" />
              </Col>
              <Col>
                <button
                  className="common_btn jag_bg py-4"
                  onClick={submitVote(p.id)}
                >
                  <span>Głosuję</span>
                </button>
              </Col> */}
          </Row>
        ))}
    </>
  );
};

export default TabLineups;
