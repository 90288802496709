import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderTop from "@/components/matchroom/slider-top";
import Tabs from "@/components/matchroom/tabs";
import api from "@/axios";
import { navigate } from "gatsby";

const Matchroom = ({ params }) => {
  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/matchroom/get`, {
          params: { id: params.id },
        });
        if (
          response.data &&
          response.data.status &&
          response.data.status == "error"
        ) {
          navigate("/", { replace: true });
        }
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        navigate("/", { replace: true });
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    let interval = setInterval(() => getData(), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined") {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [apiData && apiData.length]);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Matchroom" loadVelocity={true}>
          <HeaderOneTop />
          <HeaderOne />
          <SliderTop data={!loading && apiData} />
          <Tabs data={!loading && apiData} />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Matchroom;
