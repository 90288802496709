import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

const TabStats = ({ data }) => {
  const { matchStats, homeCrestImage, awayCrestImage } = data;
  return (
    <Container className="tab-stats-container">
      <Row className="mb-4">
        <h2 className="text-uppercase">Statystyki meczowe</h2>
      </Row>
      {!matchStats ? (
        <Row className="my-5 justify-content-center align-items-center text-center matchroom-stats-logos px-5">
          Brak dostępnych statystyk.
        </Row>
      ) : (
        <>
          <Row className="my-5 justify-content-center align-items-center text-center matchroom-stats-logos px-5">
            <Col className="text-start">
              <img
                src={
                  homeCrestImage
                    ? `${process.env.GATSBY_API_BASE_URL}/${homeCrestImage.path}`
                    : ""
                }
                className="img-fluid"
              />
            </Col>
            <Col className="text-end">
              <img
                src={
                  awayCrestImage
                    ? `${process.env.GATSBY_API_BASE_URL}/${awayCrestImage.path}`
                    : ""
                }
                className="img-fluid"
              />
            </Col>
          </Row>
          {matchStats?.possessionPercentage && (
            <>
              <Row className="px-4 mb-3 fw-bold">
                <Col className="text-start">
                  {matchStats.possessionPercentage.hvalue_prc}%
                </Col>
                <Col className="text-center text-uppercase">
                  Posiadanie piłki
                </Col>
                <Col className="text-end">
                  {matchStats.possessionPercentage.avalue_prc}%
                </Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.possessionPercentage.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.possessionPercentage.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
          {matchStats?.totalScoringAtt && (
            <>
              <Row className="px-4 mb-3 fw-bold mt-5">
                <Col className="text-start">
                  {matchStats.totalScoringAtt.hvalue}
                </Col>
                <Col className="text-center text-uppercase">
                  Strzały na bramkę
                </Col>
                <Col className="text-end">
                  {matchStats.totalScoringAtt.avalue}
                </Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.totalScoringAtt.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.totalScoringAtt.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
          {matchStats?.successfulShots && (
            <>
              <Row className="px-4 mb-3 fw-bold mt-5">
                <Col className="text-start">
                  {matchStats.successfulShots.hvalue}
                </Col>
                <Col className="text-center text-uppercase">Strzały celne</Col>
                <Col className="text-end">
                  {matchStats.successfulShots.avalue}
                </Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.successfulShots.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.successfulShots.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
          {matchStats?.shotOffTarget && (
            <>
              <Row className="px-4 mb-3 fw-bold mt-5">
                <Col className="text-start">
                  {matchStats.shotOffTarget.hvalue}
                </Col>
                <Col className="text-center text-uppercase">
                  Strzały niecelne
                </Col>
                <Col className="text-end">
                  {matchStats.shotOffTarget.avalue}
                </Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.shotOffTarget.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.shotOffTarget.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
          {matchStats?.totalPass && (
            <>
              <Row className="px-4 mb-3 fw-bold mt-5">
                <Col className="text-start">{matchStats.totalPass.hvalue}</Col>
                <Col className="text-center text-uppercase">Podania</Col>
                <Col className="text-end">{matchStats.totalPass.avalue}</Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.totalPass.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.totalPass.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
          {matchStats?.accuratePass && (
            <>
              <Row className="px-4 mb-3 fw-bold mt-5">
                <Col className="text-start">
                  {matchStats.accuratePass.hvalue}
                </Col>
                <Col className="text-center text-uppercase">Podania celne</Col>
                <Col className="text-end">{matchStats.accuratePass.avalue}</Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.accuratePass.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.accuratePass.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
          {matchStats?.totalYellowCard && (
            <>
              <Row className="px-4 mb-3 fw-bold mt-5">
                <Col className="text-start">
                  {matchStats.totalYellowCard.hvalue}
                </Col>
                <Col className="text-center text-uppercase">Żółte kartki</Col>
                <Col className="text-end">
                  {matchStats.totalYellowCard.avalue}
                </Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-home"
                    variant="danger"
                    now={matchStats.totalYellowCard.hvalue_prc}
                  />
                </Col>
                <Col className="text-end">
                  <ProgressBar
                    className="matchroom-progressbar-stats matchroom-progressbar-stat-away"
                    variant="warning"
                    now={matchStats.totalYellowCard.avalue_prc}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default TabStats;
